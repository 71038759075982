<template>
    <div class="w-full flex">
        <div style="width: 415px" class="print:hidden">
            <slot name="aside"/>
        </div>
        <div class="flex-1 min-h-screen bg-gray-300 py-8 print:py-0">
            <slot/>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';
const ReportView = defineComponent({
});

export default ReportView;
</script>

<style>
@page {
  size: A4;
}

@page {
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  margin-bottom: 0cm;
 
}

</style>