<template>
    <PrintView>
        <template #aside>
            <div
                class="border-r border-neutral-3 fixed top-0 bottom-0 flex flex-col"
                style="width: 415px"
            >
                <!-- Filtros Header -->
                <div
                    class="
                        relative
                        h-18
                        w-full
                        border-b border-neutral-1
                        px-7
                        flex
                        items-center
                        z-30
                        flex-shrink-0
                    "
                >
                    <!-- <img src="@/assets/icons/filters.svg" class="w-7" /> -->
                    <Icon icon="filters" :color="defineColorByClient" />
                    <div class="flex-1 pl-7">
                        <p class="font-bold text-lg leading-5">Filtros</p>
                        <p class="text-sm leading-5">Relatório de agendamento</p>
                    </div>
                </div>

                <!-- Filtros content -->
                <div class="flex-1 overflow-auto light-scroll px-10 py-7">
                    <p class="text-lg font-medium">Dados para exibição no relatório</p>

                    <!-- Dados do candidato -->
                    <div class="border-b border-neutral-2 flex items-center py-3">
                        <img src="@/assets/icons/filter_dados.svg" />
                        <p class="flex-1 px-4 font-medium">Dados do candidato</p>
                        <Switch
                            radioValue="reprovado"
                            name="filtro"
                            v-model="filters.candidateData"
                            :activeColor="defineColorByClient"
                        />
                    </div>

                    <!-- Horário da irregularidade -->
                    <div class="border-b border-neutral-2 flex items-center py-3">
                        <img src="@/assets/icons/filter_hora.svg" />
                        <p class="flex-1 px-4 font-medium">Horário da irregularidade</p>
                        <Switch
                            radioValue="reprovado"
                            name="filtro"
                            v-model="filters.irregularityTime"
                            :activeColor="defineColorByClient"
                        />
                    </div>

                    <!-- Responsável pela auditoria -->
                    <div class="border-b border-neutral-2 flex items-center py-3">
                        <img src="@/assets/icons/filter_responsavel.svg" />
                        <p class="flex-1 px-4 font-medium">Responsável pela auditoria</p>
                        <Switch
                            radioValue="reprovado"
                            name="filtro"
                            v-model="filters.auditResponsable"
                            :activeColor="defineColorByClient"
                        />
                    </div>

                    <!-- Horário da auditoria -->
                    <div class="border-b border-neutral-2 flex items-center py-3">
                        <img src="@/assets/icons/filter_hora-auditoria.svg" />
                        <p class="flex-1 px-4 font-medium">Horário da auditoria</p>
                        <Switch
                            radioValue="reprovado"
                            name="filtro"
                            v-model="filters.auditTime"
                            :activeColor="defineColorByClient"
                        />
                    </div>
                </div>

                <!-- Footer -->
                <div class="flex px-10 py-7 border-t border-neutral-3">
                    <button class="hollow flex-1 h-12" :style="{ color: defineColorByClient, borderColor: defineColorByClient }" @click="clear">Limpar</button>
                    <div class="w-4"></div>
                    <button class="primary flex-1 h-12" :style="{ backgroundColor: defineColorByClient }" @click="print">Imprimir</button>
                </div>
            </div>
        </template>

        <template #default>
            <Transition name="fade" mode="out-in">
                <div
                    class="h-screen flex items-center justify-center"
                    v-if="state.loading"
                >
                    Carregando
                </div>

                <PrintPage v-else-if="state.record">
                    <template #header>
                        <div
                            class="flex items-center justify-between w-full"
                            :style="{ backgroundColor: '#fff' }"
                            style="height: 20mm; padding: 0 10mm"
                        >
                            <img :src="defineLogoByClient" style="height: 8mm" />
                            <p class="font-bold reports-title text-white" :style="{ color: defineColorByClient }">
                                {{ state.record.examId }}
                            </p>
                        </div>
                    </template>

                    <template #default>
                        <!-- Conteúdo -->
                        <div class="reports__content flex w-full break-all">
                            <!-- Sessão da esquerda -->
                            <div
                                class="reports__left-section w-1/3"
                                style="padding-right: 1cm"
                                v-if="
                                    filters.candidatePicture ||
                                        filters.candidateData ||
                                        filters.responses
                                "
                            >

                                <template v-if="filters.candidateData">
                                    <div
                                        class="flex items-center"
                                        style="margin-bottom: 2mm; margin-top: 5mm"
                                    >
                                        <!-- <img src="@/assets/icons/candidato.svg" /> -->
                                        <Icon icon="candidato" :color="defineColorByClient" />
                                        <p class="font-bold ml-2 reports-title">CANDIDATO</p>
                                    </div>

                                    <!-- Cpf -->
                                    <div class="flex items-center" style="margin-bottom: 5mm">
                                        <label class="reports-text" style="width: 1cm">CPF</label>
                                        <p class="reports-text font-medium">
                                            {{ state.record.userCpf }}
                                        </p>
                                    </div>

                                    <div class="h-px w-full bg-neutral-black"></div>
                                </template>
                            </div>

                            <!-- Sessão da direita -->
                            <div class="reports__right-section flex-1">
                            
                                <div
                                    class="
                    flex
                    justify-between
                    items-center
                    border-b-2 border-neutral-black
                  "
                                    style="padding: 3mm 0; margin-bottom: 3mm"
                                >
                                    <p class="reports-title font-medium">STATUS DO EXAME</p>
                                    <p class="reports-title font-medium">{{ formattedDate }}</p>
                                </div>

                                <div class="flex items-center" style="margin-bottom: 0.5cm">
                                    <!-- <img src="@/assets/icons/irregularidades.svg" /> -->
                                    <Icon icon="irregularidades" :color="defineColorByClient" />
                                    <p class="font-bold ml-2 reports-title">IRREGULARIDADES</p>
                                </div>

                                <!-- Irregularidades -->
                                <Irregularities>
                                    <Irregularity
                                        v-for="alert in alerts"
                                        :key="alert.id"
                                        :alert="alert"
                                        :showIrregularityTime="filters.irregularityTime"
                                        :showAuditResponsable="filters.auditResponsable"
                                        :showAuditTime="filters.auditTime"
                                    />
                                </Irregularities>
                                <Irregularities>
                                    <div
                                        v-for="alert in state.record?.imageAlerts"
                                        :key="alert.id"
                                    >
                                        <IrregularityImage
                                            v-for="alerta in alert.alertas"
                                            :key="alerta.id"
                                            :alert="alerta"
                                            :showIrregularityTime="filters.irregularityTime"
                                            :showAuditResponsable="filters.auditResponsable"
                                            :showAuditTime="filters.auditTime"
                                        />
                                    </div>
                                </Irregularities>

                                <!-- <Irregularities>
                                    <Irregularity
                                        v-for="alert in alertsState?.alerts"
                                        :key="alert.id"
                                        :alert="alert"
                                        :showIrregularityTime="filters.irregularityTime"
                                        :showAuditResponsable="filters.auditResponsable"
                                        :showAuditTime="filters.auditTime"
                                    />
                                </Irregularities> -->
                                <!-- <Irregularities>
                                    <div
                                        v-for="alert in alertsState.imageAlerts"
                                        :key="alert.id"
                                    >
                                        <IrregularityImage
                                            v-for="alerta in alert.alertas"
                                            :key="alerta.id"
                                            :alert="alerta"
                                            :showIrregularityTime="filters.irregularityTime"
                                            :showAuditResponsable="filters.auditResponsable"
                                            :showAuditTime="filters.auditTime"
                                        />
                                    </div>
                                </Irregularities> -->
                            </div>
                        </div>
                        
                    </template>

                    <template #footer>
                        <div style="height: 1cm"></div>
                    </template>
                </PrintPage>
            </Transition>
        </template>
    </PrintView>
</template>

<script lang='ts'>
import { computed, defineComponent, onMounted, reactive } from "vue";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import PrintView from "@/components/PrintView.vue";
import PrintPage from "@/components/PrintPage.vue";
import Irregularities from "./Irregularities.vue";
import Irregularity from "./Irregularity.vue";
import IrregularityImage from "./IrregularityImage.vue";
import Switch from "@/components/ToogleSwitch.vue";
import Icon from "@/components/Icon.vue";
import { useRoute } from "vue-router";
import ProctoringResponseDTO from "@/dtos/ProctoringResponseDTO";
import ExameService from "@/services/ExameService";
import AlertListDTO from "@/dtos/AlertListDTO";
import ImageAlertListDTO from "@/dtos/ImageAlertListDTO";
import vm from "@/viewModels/MainViewModel";

interface ExamReportViewState {
    record: ProctoringResponseDTO | null;
    loading: boolean;
}

interface ExamesState {
    alerts: AlertListDTO[];
    imageAlerts: ImageAlertListDTO[];
}

const ExamReportView = defineComponent({
    components: { PrintPage, Irregularities, Irregularity, IrregularityImage, PrintView, Switch, Icon },
    methods: {
        getChoosedAnswer(arr: any, index: any) {
            return arr[index-1];
        },
        getLetters(letters: any, choosedAnswer: any) {
            var choosedAnswerReturn = letters[choosedAnswer-1];
            if(choosedAnswerReturn) {
                return choosedAnswerReturn;
            } else if (choosedAnswer !== ""){
                return '(discursiva)';
            }
        },
        truncate(data: string, num: any){
            const reqdString = 
              data.split("").slice(0, num).join("");
            return reqdString;
        }
    },    
    setup() {

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const defineLogoByClient = computed(() => {
            return vm.client?.logoUrl || 'https://iarisdev.blob.core.windows.net/aws/logo-easy.svg';
        });

        const filters = reactive({
            candidatePicture: true,
            candidateData: true,
            responses: true,

            irregularityTime: true,
            auditResponsable: true,
            auditTime: true,
        });

        const alertsState = reactive<ExamesState>({
            imageAlerts: [],
            alerts: []
        });

        const { params } = useRoute();

        const clear = () => {
            filters.candidatePicture = true;
            filters.candidateData = true;
            filters.responses = true;
            filters.irregularityTime = true;
            filters.auditResponsable = true;
            filters.auditTime = true;
        };

        const letters = ["A", "B", "C", "D", "E", "F"];

        const state = reactive<ExamReportViewState>({
            record: null,
            loading: true,
        });

        const alerts = computed(() => {
            // console.log(state.record);
            return state.record?.alerts.slice().sort((field1, field2) => {
                if (field1.start! < field2.start!) {
                    return -1;
                }
                if (field1.start! > field2.start!) {
                    return 1;
                }
                // a deve ser igual a b
                return 0;
            });
        });
 
        const formattedDate = computed(() => {
            return format(new Date(''+state.record?.examStartTime), "dd / MMMM / yyyy", { locale: ptBR });
        });

        const carregarExame = () => {
            state.loading = true;

            if(params.id.toString()){
                const [request] = ExameService.GetExame(params.id.toString());
                request
                    .then((resp) => {
                        // console.log(resp);

                        state.loading = false;
                        state.record = resp;
                    })
                    // .then(() => {
                    //     alertsFunc();
                    // })
                    .catch((error) => console.log(error))
                    .finally(() => (state.loading = false));
            }
        };

        onMounted(() => carregarExame());

        const print = () => {
            window.print();
        };

        return {
            filters,
            alertsState,
            clear,
            print,
            letters,
            state,
            alerts,
            formattedDate,
            defineColorByClient,
            defineLogoByClient
        };
    },
});

export default ExamReportView;
</script>

<style>
.reports__content {
  padding-left: 1cm;
  padding-right: 1cm;
}
.--mb-1cm {
  padding-bottom: 1cm;
}

.--mb-50mm {
  padding-bottom: 0, 5cm;
}
.reports-title {
  font-size: 10pt;
  line-height: 11pt;
}

.reports-subtitle {
  font-size: 8pt;
  line-height: 9pt;
}

.reports-text {
  font-size: 7pt;
  line-height: 9pt;
}
</style>