<template>
    <PrintView>
        <template #aside>
            <div
                class="border-r border-neutral-3 fixed top-0 bottom-0 flex flex-col"
                style="width: 415px"
            >
                <!-- Filtros Header -->
                <div
                    class="
            relative
            h-18
            w-full
            border-b border-neutral-1
            px-7
            flex
            items-center
            z-30
            flex-shrink-0
          "
                >
                    <img src="@/assets/icons/filters.svg" class="w-7" />
                    <div class="flex-1 pl-7">
                        <p class="font-bold text-lg leading-5">Filtros</p>
                        <p class="text-sm leading-5">Relatório de agendamentos</p>
                    </div>
                </div>

                <!-- Filtros content -->
                <div class="flex-1 overflow-auto light-scroll px-10 py-7">
                    <p class="text-lg font-medium">Dados para exibição no relatório</p>

                    <!-- Nome -->
                    <div class="border-b border-neutral-2 flex items-center py-3">
                        <img src="@/assets/icons/filter_foto.svg" />
                        <p class="flex-1 px-4 font-medium">Nome</p>
                        <Switch
                            radioValue="reprovado"
                            name="filtro"
                            v-model="filters.name"
                        />
                    </div>

                    <!-- Cpf -->
                    <div class="border-b border-neutral-2 flex items-center py-3">
                        <img src="@/assets/icons/filter_cpf.svg" />
                        <p class="flex-1 px-4 font-medium">CPF</p>
                        <Switch
                            radioValue="reprovado"
                            name="filtro"
                            v-model="filters.cpf"
                        />
                    </div>

                    <!-- Horario -->
                    <div class="border-b border-neutral-2 flex items-center py-3">
                        <img src="@/assets/icons/filter_hora2.svg" />
                        <p class="flex-1 px-4 font-medium">Horário de agendamento</p>
                        <Switch
                            radioValue="reprovado"
                            name="filtro"
                            v-model="filters.scheduleHour"
                        />
                    </div>

                    <!-- Inicioa -->
                    <div class="border-b border-neutral-2 flex items-center py-3">
                        <img src="@/assets/icons/filter_hora3.svg" />
                        <p class="flex-1 px-4 font-medium">Horário de Início</p>
                        <Switch
                            radioValue="reprovado"
                            name="filtro"
                            v-model="filters.startHour"
                        />
                    </div>

                    <!-- Termino -->
                    <div class="border-b border-neutral-2 flex items-center py-3">
                        <img src="@/assets/icons/filter_hora4.svg" />
                        <p class="flex-1 px-4 font-medium">Horário de término</p>
                        <Switch
                            radioValue="reprovado"
                            name="filtro"
                            v-model="filters.finishHour"
                        />
                    </div>
                </div>

                <!-- Footer -->
                <div class="flex px-10 py-7 border-t border-neutral-3">
                    <button class="hollow flex-1 h-12" @click="() => reset()">
                        Limpar
                    </button>
                    <div class="w-4"></div>
                    <button class="primary flex-1 h-12" @click="() => print()">
                        Imprimir
                    </button>
                </div>
            </div>
        </template>

        <template #default>
            <Transition name="fade" mode="out-in">
                <div
                    class="h-screen flex items-center justify-center"
                    v-if="state.loading"
                >
                    Carregando
                </div>
                <PrintPage style="padding: 0 10mm" v-else-if="true">
                    <template #header>
                        <div class="flex items-center justify-center" style="height: 20mm">
                            <img src="@/assets/img/logo.svg" style="height: 7mm" />
                        </div>
                    </template>

                    <template #default>
                        <table class="w-full schedules-report rounded overflow-hidden">
                            <tr class="!bg-primary">
                                <th
                                    class="text-white font-normal reports-title px-4 py-2"
                                    v-if="filters.name"
                                >
                                    Nome
                                </th>
                                <th
                                    class="text-white font-normal reports-title px-4 py-2"
                                    v-if="filters.cpf"
                                >
                                    CPF
                                </th>
                                <th
                                    class="text-white font-normal reports-title px-4 py-2"
                                    style="width: 21mm"
                                    v-if="filters.scheduleHour"
                                >
                                    Horário agendado
                                </th>
                                <th
                                    class="text-white font-normal reports-title px-4 py-2"
                                    style="width: 21mm"
                                    v-if="filters.startHour"
                                >
                                    Horário <br />de início
                                </th>
                                <th
                                    class="text-white font-normal reports-title px-4 py-2"
                                    style="width: 21mm"
                                    v-if="filters.finishHour"
                                >
                                    Horário <br />de término
                                </th>
                                <th
                                    class="text-white font-normal reports-title px-4 py-2"
                                    style="width: 25mm"
                                >
                                    Situação
                                </th>
                            </tr>
                            <tr v-for="exam in state.exams" :key="exam.agendamentoId">
                                <td v-if="filters.name">{{ exam.nomeUsuario }}</td>
                                <td v-if="filters.cpf">{{ exam.cpfUsuario }}</td>
                                <td v-if="filters.startHour">
                                    {{ exam.dataHoraInicio.substr(11, 8) }}
                                </td>
                                <td v-if="filters.scheduleHour">
                                    {{ exam.dataInicioExame.substr(11, 8) }}
                                </td>
                                <td v-if="filters.finishHour">
                                    {{ exam.dataTerminoExame.substr(11, 8) }}
                                </td>
                                <td>{{ getStatus(exam.statusAprovacao) }}</td>
                            </tr>
                        </table>
                    </template>
                </PrintPage>
            </Transition>
        </template>
    </PrintView>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, watch } from "vue";
import PrintView from "@/components/PrintView.vue";
import PrintPage from "@/components/PrintPage.vue";
import Switch from "@/components/ToogleSwitch.vue";
import { useRoute } from "vue-router";
import { parse } from "date-fns";
import ExamInfoDTO from "@/dtos/ExamInfoDTO";

interface SchedulesReportViewState {
    loading: boolean;
    exams: ExamInfoDTO[];
}

const SchedulesReportView = defineComponent({
    components: { PrintView, PrintPage, Switch },
    setup() {
        const filters = reactive({
            name: true,
            cpf: true,
            scheduleHour: true,
            startHour: true,
            finishHour: true,
        });

        const state = reactive<SchedulesReportViewState>({
            loading: true,
            exams: [],
        });

        const route = useRoute();

        const loadExams = () => {
            state.loading = true;

            const selectedDate = route.query["date"]
                ? new Date(route.query["date"].toString())
                : new Date();

            // Filtros
            const startHour = parse(
                (route.query["startHour"] as string) || "08h",
                "HH'h'",
                new Date()
            ).getHours();
            const finishHour = parse(
                (route.query["finishHour"] as string) || "23h",
                "HH'h'",
                new Date()
            ).getHours();
        };

        watch(
            () => filters,
            () => {
                if (!filters.name && !filters.cpf) {
                    filters.name = true;
                }
            },
            { deep: true }
        );

        const reset = () => {
            filters.name = true;
            filters.cpf = true;
            filters.scheduleHour = true;
            filters.startHour = true;
            filters.finishHour = true;
        };

        const print = () => window.print();

        const getStatus = (
            status:
            | "EM_ABERTO"
            | "CANCELADO"
            | "APROVADO"
            | "REPROVADO"
            | "EM_EXECUCAO"
            | "EM_AUDITORIA"
            | "DESCLASSIFICADO"
        ) => {
            switch (status) {
                case "REPROVADO":
                    return "Reprovado";
                case "CANCELADO":
                    return "Cancelado";
                case "APROVADO":
                    return "Aprovado";
                case "EM_EXECUCAO":
                    return "Em Execução";
                case "EM_ABERTO":
                    return "Em aberto";
                case "EM_AUDITORIA":
                    return "Auditoria";
                case "DESCLASSIFICADO":
                    return "Desclassificado";
                default:
                    return "";
            }
        };

        onMounted(() => loadExams());

        return { filters, reset, print, state, getStatus };
    },
});

export default SchedulesReportView;
</script>

<style>
.schedules-report th,
.schedules-report td {
  padding: 2mm;
  text-align: center;
}
.schedules-report th:first-child,
.schedules-report td:first-child {
  padding-left: 3mm;
  text-align: left;
}
.schedules-report tr {
  border-bottom: solid #e8e8e8 2px;
  page-break-inside: avoid;
}

.schedules-report tr:nth-child(odd) {
  background: #d9f0e9;
}

.schedules-report td,
.schedules-report th {
  font-size: 10pt;
  line-height: 11pt;
  page-break-inside: avoid;
}
</style>