<template>
    <tr class="irregularity">
        <td class="max-w-max" style="width: 11mm" v-if="showIrregularityTime">
            <p class="reports-text">{{ initialTime }}</p>
            <p class="reports-text">{{ finalTime }}</p>
        </td>
        <td style="width: 9mm" class="relative">
            <div
                class="absolute bottom-0 w-px bg-gray-300 top-0 z-0"
                style="left: calc(50% - 0.5px)"
            ></div>
            <div
                style="width: 1.5mm; height: 1.5mm"
                class="rounded-full mx-auto z-10 relative"
                :style="{ 'background-color': statusColor }"
            ></div>
        </td>
        <td class="text-left" style="width: 45mm">
            <!-- <p class="reports-subtitle font-medium">{{ tradutorAlerta(alert.tipo) }}</p> -->
            <p class="reports-text pr-1" :style="{ color: statusColor }">
                {{ tradutorAlerta(alert.category, alert.description) }}
            </p>
        </td>
        <td class="text-right">
            <p class="reports-text" :style="{ color: statusColor }">
                {{ statusName }}
            </p>
        </td>
        <td style="width: 9mm">
            <img
                :src="require(`@/assets/icons/${statusIcon}.svg`)"
                style="width: 3.5mm"
                class="mx-auto"
            />
        </td>
        <td style="width: 24mm" v-if="showAuditResponsable">
            <p class="reports-text">
                {{ alert.auditorId || "Aguardando auditoria" }}
            </p>
        </td>
        <td class="text-right" style="width: 14mm" v-if="showAuditTime">
            <p class="reports-text">{{ auditDateString }}</p>
            <p class="reports-text">{{ auditTimeString }}</p>
        </td>
    </tr>
</template>

<script lang='ts'>
import { AlertaDTO } from "@/dtos/AlertaDTO";
import { format } from "date-fns";
import { computed, defineComponent, PropType } from "vue";
import { tradutorAlerta } from "@/utils/stringFunctions";

const Irregularity = defineComponent({
    props: {
        showIrregularityTime: {
            type: Boolean,
            default: true,
        },
        showAuditResponsable: {
            type: Boolean,
            default: true,
        },
        showAuditTime: {
            type: Boolean,
            default: true,
        },
        alert: {
            type: Object as PropType<AlertaDTO>,
            required: true,
        },
    },
    setup(props) {
        const initialTime = computed(() =>
            format(new Date(0, 0, 0, 0, 0, 0, props.alert.start), "HH:mm:ss")
        );
        const finalTime = computed(() =>
            format(new Date(0, 0, 0, 0, 0, 0, props.alert.end), "HH:mm:ss")
        );

        const statusName = computed(() => {
            switch (props.alert.alertStatus) {
                case "RELEASED":
                    return "Liberado";
                case "BLOCKED":
                    return "Não liberado";
                case "SYSTEM_FAILURE":
                    return "Falha do sistema";
                default:
                    return "Não auditado";
            }
        });

        const statusIcon = computed(() => {
            switch (props.alert.alertStatus) {
                case "RELEASED":
                    return "check-circle";
                case "BLOCKED":
                    return "times-circle";
                case "SYSTEM_FAILURE":
                    return "alert";
                default:
                    return "cross-eye";
            }
        });

        const statusColor = computed(() => {
            switch (props.alert.alertStatus) {
                case "RELEASED":
                    return "#04986D";
                case "BLOCKED":
                    return "#D95454";
                case "SYSTEM_FAILURE":
                    return "#DF945D";
                default:
                    return "#363738";
            }
        });

        const audited = computed(() => props.alert.auditorId != null);

        const auditDate = computed(() => {
            return audited.value ? new Date(props.alert.date) : null;
        });

        const auditDateString = computed(() =>
            auditDate.value
                ? format(auditDate.value, "dd/MM/yyyy")
                : new Date().toLocaleDateString()
        );
        const auditTimeString = computed(() =>
            auditDate.value ? format(auditDate.value, "HH:mm:ss") : ""
        );

        return {
            initialTime,
            finalTime,
            statusName,
            statusIcon,
            statusColor,
            auditDateString,
            auditTimeString,
            tradutorAlerta
        };
    },
});

export default Irregularity;
</script>

<style>
.irregularity {
  page-break-inside: avoid;
}
.irregularity td {
  padding: 1.5mm 0;
  page-break-inside: avoid;
}
</style>