<template>
    <div class="print-page bg-white shadow-lg mb-8 last:mb-0 print:shadow-none print:mb-0 mx-auto">
        <table class="w-full border-collapse">
            <thead>
                <tr>
                    <td class="p-0 relative z-50">
                        <slot name="header"/>
                    </td>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td class="p-0">
                        <slot/>
                    </td>
                </tr>
            </tbody>

            <tfoot>
                <tr>
                    <td>
                        <slot name="footer"/>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

const PrintPage = defineComponent({
});

export default PrintPage;
</script>

<style>
.print-page {
    width: 210mm;
    min-height: 297.1mm
}
@media print {
    .print-page * {
         -webkit-print-color-adjust: exact;
    }
}
</style>