<template>
    <div class="w-full relative">
        <!-- <div class="absolute bottom-0 w-px bg-gray-300 top-0"
             style="left: 15.1mm"></div> -->
        <table class="w-full relative">
            <slot/>
        </table>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

const Irregularities = defineComponent({
});

export default Irregularities;
</script>

<style>

</style>