
import { computed, defineComponent, onMounted, reactive } from "vue";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import PrintView from "@/components/PrintView.vue";
import PrintPage from "@/components/PrintPage.vue";
import Irregularities from "./Irregularities.vue";
import Irregularity from "./Irregularity.vue";
import IrregularityImage from "./IrregularityImage.vue";
import Switch from "@/components/ToogleSwitch.vue";
import Icon from "@/components/Icon.vue";
import { useRoute } from "vue-router";
import ProctoringResponseDTO from "@/dtos/ProctoringResponseDTO";
import ExameService from "@/services/ExameService";
import AlertListDTO from "@/dtos/AlertListDTO";
import ImageAlertListDTO from "@/dtos/ImageAlertListDTO";
import vm from "@/viewModels/MainViewModel";

interface ExamReportViewState {
    record: ProctoringResponseDTO | null;
    loading: boolean;
}

interface ExamesState {
    alerts: AlertListDTO[];
    imageAlerts: ImageAlertListDTO[];
}

const ExamReportView = defineComponent({
    components: { PrintPage, Irregularities, Irregularity, IrregularityImage, PrintView, Switch, Icon },
    methods: {
        getChoosedAnswer(arr: any, index: any) {
            return arr[index-1];
        },
        getLetters(letters: any, choosedAnswer: any) {
            var choosedAnswerReturn = letters[choosedAnswer-1];
            if(choosedAnswerReturn) {
                return choosedAnswerReturn;
            } else if (choosedAnswer !== ""){
                return '(discursiva)';
            }
        },
        truncate(data: string, num: any){
            const reqdString = 
              data.split("").slice(0, num).join("");
            return reqdString;
        }
    },    
    setup() {

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const defineLogoByClient = computed(() => {
            return vm.client?.logoUrl || 'https://iarisdev.blob.core.windows.net/aws/logo-easy.svg';
        });

        const filters = reactive({
            candidatePicture: true,
            candidateData: true,
            responses: true,

            irregularityTime: true,
            auditResponsable: true,
            auditTime: true,
        });

        const alertsState = reactive<ExamesState>({
            imageAlerts: [],
            alerts: []
        });

        const { params } = useRoute();

        const clear = () => {
            filters.candidatePicture = true;
            filters.candidateData = true;
            filters.responses = true;
            filters.irregularityTime = true;
            filters.auditResponsable = true;
            filters.auditTime = true;
        };

        const letters = ["A", "B", "C", "D", "E", "F"];

        const state = reactive<ExamReportViewState>({
            record: null,
            loading: true,
        });

        const alerts = computed(() => {
            // console.log(state.record);
            return state.record?.alerts.slice().sort((field1, field2) => {
                if (field1.start! < field2.start!) {
                    return -1;
                }
                if (field1.start! > field2.start!) {
                    return 1;
                }
                // a deve ser igual a b
                return 0;
            });
        });
 
        const formattedDate = computed(() => {
            return format(new Date(''+state.record?.examStartTime), "dd / MMMM / yyyy", { locale: ptBR });
        });

        const carregarExame = () => {
            state.loading = true;

            if(params.id.toString()){
                const [request] = ExameService.GetExame(params.id.toString());
                request
                    .then((resp) => {
                        // console.log(resp);

                        state.loading = false;
                        state.record = resp;
                    })
                    // .then(() => {
                    //     alertsFunc();
                    // })
                    .catch((error) => console.log(error))
                    .finally(() => (state.loading = false));
            }
        };

        onMounted(() => carregarExame());

        const print = () => {
            window.print();
        };

        return {
            filters,
            alertsState,
            clear,
            print,
            letters,
            state,
            alerts,
            formattedDate,
            defineColorByClient,
            defineLogoByClient
        };
    },
});

export default ExamReportView;
