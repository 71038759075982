
import { AlertaDTO } from "@/dtos/AlertaDTO";
import AlertListDTO from "@/dtos/AlertListDTO";
import ImageAlertListDTO from "@/dtos/ImageAlertListDTO";
import { format } from "date-fns";
import { computed, defineComponent, PropType } from "vue";
import { tradutorAlerta } from "@/utils/stringFunctions";

const Irregularity = defineComponent({
    props: {
        showIrregularityTime: {
            type: Boolean,
            default: true,
        },
        showAuditResponsable: {
            type: Boolean,
            default: true,
        },
        showAuditTime: {
            type: Boolean,
            default: true,
        },
        alert: {
            type: Object as PropType<AlertListDTO>,
            required: true,
        },
    },
    setup(props) {
        // console.log(props.alert.start);
        const initialTime = computed(() =>
            format(new Date(0, 0, 0, 0, 0, 0, props.alert.start), "HH:mm:ss")
        );
        const finalTime = computed(() =>
            format(new Date(0, 0, 0, 0, 0, 0, props.alert.end), "HH:mm:ss")
        );

        const statusName = computed(() => {
            switch (props.alert.alertStatus) {
                case "RELEASED":
                    return "Liberado";
                case "BLOCKED":
                    return "Não liberado";
                case "SYSTEM_FAILURE":
                    return "Falha do sistema";
                default:
                    return "Não auditado";
            }
        });

        const statusIcon = computed(() => {
            switch (props.alert.alertStatus) {
                case "RELEASED":
                    return "check-circle";
                case "BLOCKED":
                    return "times-circle";
                case "SYSTEM_FAILURE":
                    return "alert";
                default:
                    return "cross-eye";
            }
        });

        const statusColor = computed(() => {
            switch (props.alert.alertStatus) {
                case "RELEASED":
                    return "#04986D";
                case "BLOCKED":
                    return "#D95454";
                case "SYSTEM_FAILURE":
                    return "#DF945D";
                default:
                    return "#363738";
            }
        });

        // const audited = computed(() => props.alert.auditorId != null);

        // const auditDate = computed(() => {
        //     return audited.value ? new Date(props.alert.date) : null;
        // });

        // const auditDateString = computed(() =>
        //     auditDate.value
        //         ? format(auditDate.value, "dd/MM/yyyy")
        //         : new Date().toLocaleDateString()
        // );
        // const auditTimeString = computed(() =>
        //     auditDate.value ? format(auditDate.value, "HH:mm:ss") : ""
        // );

        return {
            initialTime,
            finalTime,
            statusName,
            statusIcon,
            statusColor,
            // auditDateString,
            // auditTimeString,
            tradutorAlerta
        };
    },
});

export default Irregularity;
