
import { defineComponent, onMounted, reactive, watch } from "vue";
import PrintView from "@/components/PrintView.vue";
import PrintPage from "@/components/PrintPage.vue";
import Switch from "@/components/ToogleSwitch.vue";
import { useRoute } from "vue-router";
import { parse } from "date-fns";
import ExamInfoDTO from "@/dtos/ExamInfoDTO";

interface SchedulesReportViewState {
    loading: boolean;
    exams: ExamInfoDTO[];
}

const SchedulesReportView = defineComponent({
    components: { PrintView, PrintPage, Switch },
    setup() {
        const filters = reactive({
            name: true,
            cpf: true,
            scheduleHour: true,
            startHour: true,
            finishHour: true,
        });

        const state = reactive<SchedulesReportViewState>({
            loading: true,
            exams: [],
        });

        const route = useRoute();

        const loadExams = () => {
            state.loading = true;

            const selectedDate = route.query["date"]
                ? new Date(route.query["date"].toString())
                : new Date();

            // Filtros
            const startHour = parse(
                (route.query["startHour"] as string) || "08h",
                "HH'h'",
                new Date()
            ).getHours();
            const finishHour = parse(
                (route.query["finishHour"] as string) || "23h",
                "HH'h'",
                new Date()
            ).getHours();
        };

        watch(
            () => filters,
            () => {
                if (!filters.name && !filters.cpf) {
                    filters.name = true;
                }
            },
            { deep: true }
        );

        const reset = () => {
            filters.name = true;
            filters.cpf = true;
            filters.scheduleHour = true;
            filters.startHour = true;
            filters.finishHour = true;
        };

        const print = () => window.print();

        const getStatus = (
            status:
            | "EM_ABERTO"
            | "CANCELADO"
            | "APROVADO"
            | "REPROVADO"
            | "EM_EXECUCAO"
            | "EM_AUDITORIA"
            | "DESCLASSIFICADO"
        ) => {
            switch (status) {
                case "REPROVADO":
                    return "Reprovado";
                case "CANCELADO":
                    return "Cancelado";
                case "APROVADO":
                    return "Aprovado";
                case "EM_EXECUCAO":
                    return "Em Execução";
                case "EM_ABERTO":
                    return "Em aberto";
                case "EM_AUDITORIA":
                    return "Auditoria";
                case "DESCLASSIFICADO":
                    return "Desclassificado";
                default:
                    return "";
            }
        };

        onMounted(() => loadExams());

        return { filters, reset, print, state, getStatus };
    },
});

export default SchedulesReportView;
